<template>
  <Logo :image-url="tenantLogoUrl" :description="tenantName" />
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo';

export default {
  name: 'TenantLogo',
  components: { Logo },
  computed: {
    ...mapGetters('user', ['tenantLogoUrl', 'tenantName']),
  },
};
</script>
