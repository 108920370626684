var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Logo", {
    attrs: { "image-url": _vm.tenantLogoUrl, description: _vm.tenantName }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }